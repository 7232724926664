<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>FAQ Category </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  FAQ Category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createFaqCategory"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add FAQ Category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
            <v-row>
              <v-col cols="12"md="3">
                <v-text-field
                    label="Name"
                    v-model="search.name"
                    v-on:keyup.enter="searchFaqCategory"
                    @input="search.name = $event !== null ? $event : ''"
                    clearable
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                    :items="parents"
                    v-model="search.parent_id"
                    v-on:keyup.enter="searchFaqCategory"
                    @input="search.parent_id = $event !== null ? $event : ''"
                    label="Parent"
                    item-text="name"
                    item-value="id"
                    clearable
                    outlined
                    dense
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                    :items="status"
                    v-model="search.is_active"
                    v-on:keyup.enter="searchFaqCategory"
                    @input="search.is_active = $event !== null ? $event : ''"
                    label="Status"
                    item-text="name"
                    item-value="value"
                    clearable
                    outlined
                    dense
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-btn @click="searchFaqCategory()" class="btn btn-primary" style="color: #fff" :loading="loading">
                  <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Name</strong></th>
                  <th><strong>Position</strong></th>
                  <th><strong>Parent</strong></th>
                  <th><strong>Is Parent</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="faqCategories" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                    <tr v-for="(faqCategory, index) in faqCategories" :key="index">
                      <td>
                        <a @click="editFaqCategory(faqCategory)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          <i class="fa fa-sort"></i> {{ faqCategory.name }}
                        </a>
                      </td>

                      <td>
                        {{ faqCategory.position }}
                      </td>


                      <td>
                        {{ faqCategory.parent_name ?  faqCategory.parent_name : '--'}}
                      </td>

                      <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': !faqCategory.is_parent, 'badge-danger': faqCategory.is_parent }"
                        >{{ faqCategory.is_parent ? 'No' : 'Yes' }}</span>
                      </td>

                      <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': faqCategory.is_active, 'badge-danger': !faqCategory.is_active }"
                        >{{ faqCategory.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click="editFaqCategory(faqCategory)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteFaqCategory(faqCategory.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="faqCategories.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="7">No Data Found</td>
                    </tr>
                  </draggable>
                </template>

              </table>
              <b-pagination
                  v-if="faqCategories.length > 0"
                  class="pull-right mt-7"
                  @input="getAllFaqCategories"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  :disabled="loading"
                  first-number
                  last-number
              ></b-pagination>
            </div>
            <!-- table ends -->

            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>

          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllFaqCategories"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import FaqCategoryService from "@/services/cms/faq/category/FaqCategoryService";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from "vuedraggable";

const faqCategory = new FaqCategoryService();

export default {
  components: {
    CreateAndUpdate,
    draggable
  },
  name: "Index",
  data() {
    return{
      loading: false,
      isSorting: false,
      total: null,
      perPage: null,
      page: '',
      faqCategories: [],
      parents: [],
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search:{
        name: '',
        parent_id: '',
        is_active: '',
      }
    }
  },
  mounted() {
    this.getAllFaqCategories();
    this.getAllParent();
  },
  methods: {
    createFaqCategory(){
      this.$refs['create-and-update'].createFAQCategory();
    },
    editFaqCategory(faqCategory){
      this.$refs['create-and-update'].editFAQCategory(faqCategory);
    },
    getAllFaqCategories(){
      this.loading = true;
      faqCategory
          .paginate(this.search,this.page)
          .then(response => {
            this.faqCategories = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllParent(){
      faqCategory
          .getParent()
          .then(response => {
            this.parents = response.data.data;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    sort(){
      this.isSorting = true;
      faqCategory
          .sort(this.faqCategories)
          .then(response => {
            this.$snotify.success('Faq category Sorted !!');
            this.getAllFaqCategories();
            this.isSorting = false;
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchFaqCategory(){
      this.getAllFaqCategories();
    },

    deleteFaqCategory(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            faqCategory
                .delete(id)
                .then((response) => {
                  this.getAllFaqCategories();
                  this.$snotify.success("FAQ category deleted successfully");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },

}
</script>
